import React from 'react';
import Logo from '../../assets/images/logo.png';
import i18next from "i18next";
import Authorization from '../../utility/Authorization';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ProfileMenuComponentLogin from './ProfileMenuComponentLogin'
import { getRelease } from "../user-related/UserAction";

/*
we are loadingthe HeaderLogin in the HeaderComponent based on the user authorization
*/
class HeaderLogin extends React.Component {

    constructor(props) {
        super(props)
        this.state = { userName: '', releaseVersion: localStorage.getItem("releaseVersion") || "" }
    }

    getUserName() {
        let user = ''
        const authUser = Authorization.getAuthUser();
        if (authUser && authUser.name) {
            user = authUser.name
        }
        this.setState({ userName: user })
    }

    componentDidMount() {
        if (this.props.location.pathname === "/model-list") {
            this.getUserName();
            this.props.getRelease();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname && prevProps.location.pathname !== this.props.location.pathname) {
            if (this.props.location.pathname === "/model-list") {
                this.getUserName();
                this.props.getRelease();
            }
        }

        if (this.props.getReleaseStatus && prevProps.getReleaseStatus !== this.props.getReleaseStatus) {
            if (this.props.getReleaseStatus.status === 200) {
                localStorage.setItem("releaseVersion", this.props.getReleaseStatus.data.version)
                this.setState({releaseVersion: this.props.getReleaseStatus.data.version})
            }
        }
    }

    /*Use to logout the user from the application*/
    logOut() {
        Authorization.logout();
    }

    render() {
        const { location } = this.props;
        return (<div className="header dashboard-machine header-design" id="header-inner">
            <div className="header-col">
                <div className="header-logo">
                    <Link to="/">
                        <img src={Logo} alt={i18next.t('BRAND.SHORT_NAME')} loading="lazy" />
                    </Link>
                </div>
                <div className="header-text">
                    {location.pathname === '/prediction' ? <h1> {i18next.t('HEADER.JCB_LENS')}</h1> : <h1> {i18next.t('HEADER.REMOTE_DIAGNOSTICS')}</h1> }
                    {this.state.releaseVersion && <p className="release"> {i18next.t('HEADER.RELEASE_VERSION')} : {this.state.releaseVersion || "--"}</p>}
                </div>
                <div className="header-login">
                    {this.props.location.pathname === "/model-list" || this.props.location.pathname === "/my-profile-login" || this.props.location.pathname === "/change-password-login" ?
                        <ProfileMenuComponentLogin/> : <></>
                    }
                </div>
            </div>
        </div>)
    }

}

function mapStateToProps(state) {
    return {
        getReleaseStatus: state.getRelease
    };
}


/*
* mapDispatchToProps(dispatch) is used to dispatch data to the Action
*/
function mapDispatchToProps(dispatch) {
    return {
        getRelease:()=>dispatch(getRelease())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderLogin))