import {
        UPLOAD_LENS_LABEL_FAILURE,
        UPLOAD_LENS_LABEL_SUCCESS,
        EDIT_LENS_LABEL_FAILURE,
        EDIT_LENS_LABEL_SUCCESS,
        DELETE_LENS_LABEL_FAILURE,
        DELETE_LENS_LABEL_SUCCESS,
        FETCH_LENS_LABEL_FAILURE,
        FETCH_LENS_LABEL_SUCCESS,

        FETCH_LENS_IMAGE_FAILURE,
        FETCH_LENS_IMAGE_SUCCESS,
        DELETE_LENS_IMAGE_FAILURE,
        DELETE_LENS_IMAGE_SUCCESS,
        UPLOAD_LENS_IMAGE_FAILURE,
        UPLOAD_LENS_IMAGE_SUCCESS,
        FETCH_PREDICT_IMAGE_SUCCESS, 
        FETCH_PREDICT_IMAGE_FAILURE,

         
        STOP_TRAINING_SUCCESS,
        STOP_TRAINING_FAILURE,

        MODEL_TRAINING_SUCCESS, 
        MODEL_TRAINING_FAILURE,
        NOTIFY_RETRAIN,
        MODEL_TRAINING_DETAILS_FAILURE,
        MODEL_TRAINING_DETAILS_SUCCESS,

        FETCH_PREDICTION_HTML_SUCCESS,
        FETCH_PREDICTION_HTML_FAILURE,

        LABEL_SAVE_SUCCESS,
        LABEL_SAVE_FAILURE,
        MODEL_TRAINING_STATUS_SUCCESS,
        REFRESH_CLICKED_SUCCESS
    } from './LensType'

const initialState = { isFetching: false, data: [] }

//LABEL
export const createLensLabel = ( state = initialState , { type ,payload } ={} ) => {
    switch (type) {
        case UPLOAD_LENS_LABEL_FAILURE:
        case UPLOAD_LENS_LABEL_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const updateLensLabel = ( state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case EDIT_LENS_LABEL_FAILURE:
        case EDIT_LENS_LABEL_SUCCESS:
           return ({}, payload);

        default:
            return state;
    }
}

export const deleteLensLabel = (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case DELETE_LENS_LABEL_FAILURE:
        case DELETE_LENS_LABEL_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const getLensLabel = (  state = initialState , {type ,payload } ={}) => {
    switch (type) {
        case FETCH_LENS_LABEL_FAILURE:
        case FETCH_LENS_LABEL_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


//IMAGE
export const createLensImage = ( state = initialState , { type ,payload } ={} ) => {
    switch (type) {
        case UPLOAD_LENS_IMAGE_FAILURE:
        case UPLOAD_LENS_IMAGE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}



export const deleteLensImage = (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case DELETE_LENS_IMAGE_FAILURE:
        case DELETE_LENS_IMAGE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const getLensImage = (  state = initialState , {type ,payload } ={}) => {
    switch (type) {
        case FETCH_LENS_IMAGE_FAILURE:
        case FETCH_LENS_IMAGE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const getPredictImage = (  state = initialState , {type ,payload } ={}) => {
    switch (type) {
        case FETCH_PREDICT_IMAGE_FAILURE:
        case FETCH_PREDICT_IMAGE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const modelTrainingImage = (  state = initialState , {type ,payload } ={}) => {
    switch (type) {
        case MODEL_TRAINING_FAILURE:
        case MODEL_TRAINING_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const modelStopTraining = (  state = initialState , {type ,payload } ={}) => {
    switch (type) {
        case STOP_TRAINING_FAILURE:
        case STOP_TRAINING_SUCCESS:
            return ({}, payload);
        default:
            return state;
    }
}

export const modelTrainingDetails = (  state = initialState , {type ,payload } ={}) => {
    switch (type) {
        case MODEL_TRAINING_DETAILS_FAILURE:
        case MODEL_TRAINING_DETAILS_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const fetchHTMLPredict = (  state = initialState , {type ,payload } ={}) => {
    switch (type) {
        case FETCH_PREDICTION_HTML_FAILURE:
        case FETCH_PREDICTION_HTML_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const notifyRetrain = (  state = false , {type ,payload } ={}) => {
    switch (type) {
        case NOTIFY_RETRAIN:
            return ({}, payload);
        default:
            return state;
    }
}

export const reviewLabelSaveImg = (  state = initialState , {type ,payload } ={}) => {
    switch (type) {
        case LABEL_SAVE_SUCCESS:
        case LABEL_SAVE_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}

const initialTrainingStatus = ''
export const modelTrainingStatus = (  state = initialTrainingStatus , {type ,payload } ={}) => {
    switch (type) {
        case MODEL_TRAINING_STATUS_SUCCESS:
            return ({},payload);

        default:
            return state;
    }
}

const initialClickStatus = false
export const refreshClickedStatus = (  state = initialClickStatus , {type ,payload } ={}) => {
    switch (type) {
        case REFRESH_CLICKED_SUCCESS:
            return ({},payload);

        default:
            return state;
    }
}
