import React, { Suspense, lazy } from 'react';
import { Switch } from 'react-router-dom';
import PublicRouter from './PublicRouter'
import PrivateRouter from './PrivateRouter'
import Loader from '../assets/images/loading.svg';


const LoginPageComponent = lazy(() => import('../modules/login-related/login-page/LoginPageComponent'));
const ForgotPasswordComponent = lazy(() => import('../modules/login-related/forgot-password/ForgotPasswordComponent'));
const ResetPasswordComponent = lazy(() => import('../modules/login-related/reset-password/ResetPasswordComponent'));
const MachineModelComponent = lazy(() => import('../modules/machine-model-List/MachineModelComponent'));
const HomePageComponent = lazy(() => import('../modules/home-page/HomePageComponent'));
const DetailDashboardComponent = lazy(() => import('../modules/home-page/detail-dashboard/DetailDashboardComponent'));
const HelpComponent = lazy(() => import('../modules/help/HelpComponent'));
const ServicesComponent = lazy(() => import('../modules/services/ServicesComponent'));
const SelectedCategoryComponent = lazy(() => import('../modules/help/selected-category/SelectedCategoryComponent'));
const ServiceProcedure = lazy(() => import('../modules/help/service-procedure/ServiceProcedureComponent'));
const ManageUserComponent = lazy(() => import('../modules/user-related/manage-user/ManageUserComponent'));
const ChangePasswordComponent = lazy(() => import('../modules/user-related/change-password/ChangePasswordComponent'));
const ChangePasswordComponentLogin = lazy(() => import('../modules/user-related/change-password/ChangePasswordComponentLogin'));
const MyProfileComponent = lazy(() => import('../modules/user-related/my-profile/MyProfileComponent'));
const MyProfileComponentLogin = lazy(() => import('../modules/user-related/my-profile/MyProfileComponentLogin'));
const OnBoardComponent = lazy(() => import('../modules/user-related/manage-onboarding/OnBoardComponent'));
const FaultCodeComponent = lazy(() => import('../modules/faultcode/FaultCodeComponent'));
const CategoryListingComponent = lazy(() => import('../modules/dynamic-modules/category-listing/CategoryListingComponent'));
const ViewSelectedCategoryComponent = lazy(() => import('../modules/dynamic-modules/view-selected-category/ViewSelectedCategoryComponent'));
const ProcedureDetailComponent = lazy(() => import('../modules/help/procedure-detail/ProcedureDetailComponent'));
const RolesAndPermissionsComponent = lazy(() => import('../modules/user-related/manage-role/RolesAndPermissionsComponent'));
const ManageHtmlComponent = lazy(() => import('../modules/user-related/manage-html/ManageHtmlComponent'));
const ManageCategoryComponent = lazy(() => import('../modules/user-related/manage-category/ManageCategoryComponent'));
const LogComponent = lazy(() => import('../modules/user-related/manage-log/LogComponent'));
const ViewHtmlComponent = lazy(() => import('../modules/help/view-html/ViewHtmlComponent'));
const ModelVariantComponent = lazy(() => import('../modules/user-related/manage-settings/ModelVariantComponent'));
const DocumentComponent = lazy(() => import('../modules/user-related/manage-document/DocumentComponent'));
const ReleaseNotesComponent = lazy(() => import('../modules/user-related/release-notes/ReleaseNotesComponent'));
const ReleaseNotesComponentLogin = lazy(() => import('../modules/user-related/release-notes/ReleaseNotesComponentLogin'));
const ManageLabelsComponent = lazy(() => import('../modules/jcb-lens/manage-lens/ManageLabelsComponent'));
const ManageLensReviewComponent = lazy(() => import('../modules/jcb-lens/manage-review/ManageReview.js'));
const UploadImageComponent = lazy(()=> import('../modules/jcb-lens/manage-lens/UploadImageComponent'));
const JCBLensPredictionComponent  = lazy(()=> import('../modules/jcb-lens/jcb-lens-prediction/JCBLensPrediction.js'));
const Authentication  = lazy(()=> import('../modules/login-related/login-page/Authentication.js'));

const ManageECUComponent  = lazy(()=> import('../modules/user-related/manage-flash/ManageECUComponent.js'));
const ManageFlashComponent  = lazy(()=> import('../modules/user-related/manage-flash/ManageFlashComponent.js'));

class Router extends React.Component {

    constructor(props){
        super(props)
        this.pathurl = ""
    }

    componentDidUpdate(){
        this.pathurl = this.props.location
      }
      

      render() {
          return (
              
              
              <Suspense fallback={<div className="loader-content">
            <img src={Loader} loading="lazy" alt="loader" />
        </div>}>
            
            <Switch >
                <PublicRouter exact={true} path="/" component={LoginPageComponent} />
                <PublicRouter exact={true} path="/login" component={LoginPageComponent} />
                <PublicRouter exact={true} path="/auth" component={Authentication} />
                <PublicRouter exact={true} path="/prediction" component={JCBLensPredictionComponent} />
                <PublicRouter exact={true}  path="/forgot-password" component={ForgotPasswordComponent} />
                <PublicRouter exact={true}  path="/reset-password/:id" component={ResetPasswordComponent} />


                <PrivateRouter exact={true} path="/model-list" mfaCheck={true} component={MachineModelComponent} />
                <PrivateRouter exact={true} path="/home"  mfaCheck={true} component={HomePageComponent} />
                <PrivateRouter exact={true} path="/help"  mfaCheck={true} component={HelpComponent} />
                <PrivateRouter exact={true} path="/services" mfaCheck={true} component={ServicesComponent} />
                <PrivateRouter exact={true} path="/help/category/:id" mfaCheck={true}  component={SelectedCategoryComponent} />
                <PrivateRouter exact={true} path="/help/fault-detail/:categoryId/:faultcodeId" mfaCheck={true} component={ServiceProcedure} />
                <PrivateRouter exact={true} path="/faultcode/fault-detail/:faultcodeId" mfaCheck={true} component={ServiceProcedure} />
                <PrivateRouter exact={true} path="/faultcode/fault/procedure/:Id" mfaCheck={true} component={ProcedureDetailComponent} />
                <PrivateRouter exact={true} path="/help/fault/procedure/:categoryId/:Id" mfaCheck={true} component={ProcedureDetailComponent} />
                <PrivateRouter exact={true} path="/help/failure/procedure/:categoryId/:Id" mfaCheck={true} component={ProcedureDetailComponent} />
                <PrivateRouter exact={true} path="/help/html/:categoryId/:id" mfaCheck={true} component={ViewHtmlComponent} />
                <PrivateRouter exact={true} path="/manage-user" routeGuard={true} mfaCheck={true} component={ManageUserComponent} />
                <PrivateRouter exact={true} path="/manage-category" routeGuard={true} mfaCheck={true} component={ManageCategoryComponent} />
                <PrivateRouter exact={true} path="/manage-html" routeGuard={true} mfaCheck={true} component={ManageHtmlComponent} />
                <PrivateRouter exact={true} path="/faultcode" routeGuard={true} mfaCheck={true} component={FaultCodeComponent} />
                <PrivateRouter exact={true} path="/model/:moduleId/:modelName" mfaCheck={true} component={CategoryListingComponent} />
                <PrivateRouter exact={true} path="/model/:moduleId/:modelName/:categoryId" routeGuard={true} mfaCheck={true} component={ViewSelectedCategoryComponent} />
                <PrivateRouter exact={true} path="/setup" mfaCheck={true} component={CategoryListingComponent} />
                <PrivateRouter exact={true} path="/setup/:categoryId" mfaCheck={true} component={ViewSelectedCategoryComponent} />
                <PrivateRouter exact={true} path="/datalogger" mfaCheck={true} component={CategoryListingComponent} />
                <PrivateRouter exact={true} path="/datalogger/:categoryId" mfaCheck={true} component={ViewSelectedCategoryComponent} />
                <PrivateRouter exact={true} path="/canlogger"  mfaCheck={true} component={CategoryListingComponent} />
                <PrivateRouter exact={true} path="/canlogger/:categoryId" mfaCheck={true} component={ViewSelectedCategoryComponent} />
                <PrivateRouter exact={true} path="/change-password" routeGuard={true}  mfaCheck={true} component={ChangePasswordComponent} />
                <PrivateRouter exact={true} path="/change-password-login" routeGuard={true} mfaCheck={true} component={ChangePasswordComponentLogin} />
                <PrivateRouter exact={true} path="/my-profile" routeGuard={true}  mfaCheck={true} component={MyProfileComponent} />
                <PrivateRouter exact={true} path="/my-profile-login" routeGuard={true} mfaCheck={true} component={MyProfileComponentLogin} />
                <PrivateRouter exact={true} path="/manage-role" routeGuard={true} mfaCheck={true} component={RolesAndPermissionsComponent} />
                <PrivateRouter exact={true} path="/manage-onboard" routeGuard={true} mfaCheck={true} component={OnBoardComponent} />
                <PrivateRouter exact={true} path="/manage-modelvariant" routeGuard={true} mfaCheck={true} component={ModelVariantComponent} />
                <PrivateRouter exact={true} path="/manage-document" routeGuard={true}   mfaCheck={true} component={DocumentComponent} />
                <PrivateRouter exact={true} path="/log"  routeGuard={true}  mfaCheck={true} component={LogComponent} />
                <PrivateRouter exact={true} path="/log-dashboard"  routeGuard={true} mfaCheck={true} component={DetailDashboardComponent} />
                <PrivateRouter exact={true} path="/release-notes" routeGuard={true} mfaCheck={true}  component={ReleaseNotesComponent} />
                <PrivateRouter exact={true} path="/release-notes-login" routeGuard={true} mfaCheck={true} component={ReleaseNotesComponentLogin} />
                <PrivateRouter exact={true} path="/manage-labels" routeGuard={true} mfaCheck={true} component={ManageLabelsComponent} />
                <PrivateRouter exact={true} path="/manage-lens-review" routeGuard={true} mfaCheck={true} component={ManageLensReviewComponent} />
                <PrivateRouter exact={true} path="/upload-images" routeGuard={true} mfaCheck={true} component={UploadImageComponent} />
                <PrivateRouter exact={true} path="/manage-ecu" routeGuard={true} mfaCheck={true} component={ManageECUComponent} />
                <PrivateRouter exact={true} path="/manage-flash" routeGuard={true}  mfaCheck={true} component={ManageFlashComponent} />
                <PublicRouter   path="/"  exact={false} />

            </Switch>
        </Suspense>
    )
      }
}

export default Router