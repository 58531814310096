//Label
export const UPLOAD_LENS_LABEL_SUCCESS = 'UPLOAD_LENS_LABEL_SUCCESS';
export const UPLOAD_LENS_LABEL_FAILURE = 'UPLOAD_LENS_LABEL_FAILURE';

export const EDIT_LENS_LABEL_SUCCESS = 'EDIT_LENS_LABEL_SUCCESS';
export const EDIT_LENS_LABEL_FAILURE = 'EDIT_LENS_LABEL_FAILURE';

export const DELETE_LENS_LABEL_SUCCESS = 'DELETE_LENS_LABEL_SUCCESS';
export const DELETE_LENS_LABEL_FAILURE = 'DELETE_LENS_LABEL_FAILURE';

export const FETCH_LENS_LABEL_SUCCESS = 'FETCH_LENS_LABEL_SUCCESS';
export const FETCH_LENS_LABEL_FAILURE = 'FETCH_LENS_LABEL_FAILURE';


//Image
export const UPLOAD_LENS_IMAGE_SUCCESS = 'UPLOAD_LENS_IMAGE_SUCCESS';
export const UPLOAD_LENS_IMAGE_FAILURE = 'UPLOAD_LENS_IMAGE_FAILURE';

export const DELETE_LENS_IMAGE_SUCCESS = 'DELETE_LENS_IMAGE_SUCCESS';
export const DELETE_LENS_IMAGE_FAILURE = 'DELETE_LENS_IMAGE_FAILURE';

export const FETCH_LENS_IMAGE_SUCCESS = 'FETCH_LENS_IMAGE_SUCCESS';
export const FETCH_LENS_IMAGE_FAILURE = 'FETCH_LENS_IMAGE_FAILURE';

//Predict image
export const FETCH_PREDICT_IMAGE_SUCCESS = 'FETCH_PREDICT_IMAGE_SUCCESS' 
export const FETCH_PREDICT_IMAGE_FAILURE = 'FETCH_PREDICT_IMAGE_FAILURE'

export const STOP_TRAINING_SUCCESS = "STOP_TRAINING_SUCCESS";
export const STOP_TRAINING_FAILURE = "STOP_TRAINING_FAILURE";

//model Training
export const MODEL_TRAINING_SUCCESS = 'MODEL_TRAINING_SUCCESS';
export const MODEL_TRAINING_FAILURE = 'MODEL_TRAINING_FAILURE';
export const NOTIFY_RETRAIN = 'NOTIFY_RETRAIN';
//model Training Idle
export const MODEL_TRAINING_DETAILS_SUCCESS = 'MODEL_TRAINING_DETAILS_SUCCESS';
export const MODEL_TRAINING_DETAILS_FAILURE = 'MODEL_TRAINING_DETAILS_FAILURE';

//model Training Idle
export const FETCH_PREDICTION_HTML_SUCCESS = 'FETCH_PREDICTION_HTML_SUCCESS';
export const FETCH_PREDICTION_HTML_FAILURE = 'FETCH_PREDICTION_HTML_FAILURE';


//label save
export const LABEL_SAVE_SUCCESS = 'LABEL_SAVE_SUCCESS';
export const LABEL_SAVE_FAILURE = 'LABEL_SAVE_FAILURE';

//model Training Idle
export const MODEL_TRAINING_STATUS_SUCCESS = 'MODEL_TRAINING_STATUS_SUCCESS'; 
export const REFRESH_CLICKED_SUCCESS = 'REFRESH_CLICKED_SUCCESS';       