
import CommonApi from '../utility/CommonApi';
import  cryptLib from 'cryptlib'
class Authorization {


    constructor() {
        this.authUser = null;
        this.mfaAccess = null;
        this.faultCodeFrom = null;
        this.authUserId = null;
        this.authRole = null;
    }


    isLoggedIn() {
        return typeof localStorage.getItem("userInfo") === "string";
    }
    isVerified() {
        return typeof localStorage.getItem("userVerified") === "string";
    }
    getFaultCodeFromType() {
        return typeof localStorage.getItem("FaultCodeFrom") === "string";
    }

    isVariantId() {
        return typeof localStorage.getItem("selectedVariant") === "string";
    }

    isModelId() {
        return typeof localStorage.getItem("selectedModel") === "string";
    }

    isVariantSelect() {
        return typeof localStorage.getItem("selectedVariant") === "string";
    }

    setAuthUser() {
        this.authUser = JSON.parse(localStorage.getItem("userInfo"));
    }

    setMfaAccess(){
        this.mfaAccess = localStorage.getItem("userVerified") == 'true';
    }
  
    getFaultCodeFrom() {
        this.faultCodeFrom = JSON.parse(localStorage.getItem("FaultCodeFrom"));
    }


    //To fetch Router guard info 
    getModulesList() {
        const data = this.getAuthUser();
        if (data.modules) {
            return data.modules
        } else {
            return null;
        }

    }

    //To fetch Router guard info 
    getSelectedModule(val) {
        const moduleList = this.getModulesList()
        if (moduleList && moduleList[val]) {
            return moduleList[val];
        } else {

            return null;
        }

    }



    //To fetch Router guard info 
    getSelectedModuleStatus(val) {
        const moduleList = this.getModulesList()
        return (moduleList && moduleList[val]) 

    }

    //To fetch Router guard info 
    getSelectedModuleAction(val, type) {
        const moduleList = this.getModulesList()
        if (moduleList && moduleList[val]) {

            if (moduleList[val][type]) {
                return moduleList[val][type] ? true : false;
            } else {
                return false;
            }
        } else {

            return false;
        }

    }

    getOrgName() {
        let orgName = null
        const result = JSON.parse(localStorage.getItem("userInfo"));
        if (result.orgName) {
            orgName = result.orgName
        }
        return orgName;

    }



    getAuthUser() {
        if (this.isLoggedIn()) {
            this.setAuthUser();
        }
        return this.authUser;
    }

    getMfaVerified(){
        if(this.isVerified){
            this.setMfaAccess();
        }
        return this.mfaAccess;
    }


    getSelectedFaultCodeFrom(val){
        if(this.getFaultCodeFromType()){
            this.getFaultCodeFrom()
        }
        if(this.faultCodeFrom === null){
            return false
        }
        
        if(val === 'faultcode'){
            if(this.faultCodeFrom === 'RD'){
                return false
            }else if(this.faultCodeFrom === 'INTELLI'){
                return true
            }
        }
        if(val === 'model'){
            if(this.faultCodeFrom === 'RD'){
                return true
            }else if(this.faultCodeFrom === 'INTELLI'){
                return false
            }
        }
    }
    setAuthType(val) {
        if (typeof Storage !== "undefined") {
            if (val) {
                localStorage.setItem('AuthType', JSON.stringify(val));
            }
        } else {
            console.error("local storage is not supported");
        }

    }

    setFaultCodeFrom(val) {
        if (typeof Storage !== "undefined") {
            if (val) {
                localStorage.setItem('FaultCodeFrom', JSON.stringify(val));
            }
        } else {
            console.error("local storage is not supported");
        }

    }

    setEnviroment(val) {
        if (typeof Storage !== "undefined") {
            if (val) {
                localStorage.setItem('Environment', JSON.stringify(val));
            }
        } else {
            console.error("local storage is not supported");
        }

    }


    getAuthType() {
        if (typeof Storage !== "undefined") {
            if (localStorage.getItem("AuthType")) {
                const result = JSON.parse(localStorage.getItem("AuthType"))
                return result
            }
            return null;
        } else {
            console.error("local storage is not supported");
        }
    }



    updateAuthType(){
        let  AuthType 
        AuthType =  this.getAuthType()
         if(AuthType !== null){
            AuthType.guard = false;
            this.setAuthType(AuthType)
            return true;
         }

    }


    setVinNumber(val) {
        if (typeof Storage !== "undefined") {
            if (val) {
                localStorage.setItem('vin', JSON.stringify(val));
            }
        } else {
            console.error("local storage is not supported");
        }

    }


    setMfaVerify(val){
        if (typeof Storage !== "undefined") {
            if (val) {
                this.mfaAccess = localStorage.setItem('userVerified',val);
            }
        } else {
            console.error("local storage is not supported");
        }
    }


    getVinNumber() {
        return JSON.parse(localStorage.getItem("vin"));
    }



    logout(redirect="") {
        // Logout API call
        CommonApi.callGetAPI(process.env.REACT_APP_BACKEND_BASE_URL +  '/logout' , (result) => {})

        if (typeof Storage !== "undefined") {
            const userType = localStorage.getItem('userType')
            localStorage.clear();
            localStorage.setItem("userType",userType)
            this.authUser = null;
            if(redirect === ''){
                window.location.href = process.env.REACT_APP_HOMEPAGE;
            }
        } else {
            console.error("local storage is not supported");
        }
    }

    removeVariantDetail() {
        if (typeof Storage !== "undefined") {
            if (localStorage.getItem("selectedVariant")) {
                localStorage.removeItem("selectedVariant");

            }
        } else {
            console.error("local storage is not supported");
        }
    }

    removeMachineModel() {
        if (typeof Storage !== "undefined") {
            if (localStorage.getItem("selectedModel")) {
                localStorage.removeItem("selectedModel");

            }
        } else {
            console.error("local storage is not supported");
        }
    }



    setVariantDetail(val) {
        if (val) {
            localStorage.setItem('selectedVariant', JSON.stringify(val));
        }
    }

    setMachineModel(val) {
        if (typeof Storage !== "undefined") {
            if (val) {
                localStorage.setItem('selectedModel', JSON.stringify(val));
            }
        } else {
            console.error("local storage is not supported");
        }
    }


    setDynamicModelImg(val) {
        if (typeof Storage !== "undefined") {
            if (val) {
                localStorage.setItem('dynamicModuleIMg', JSON.stringify(val));
            }
        } else {
            console.error("local storage is not supported");
        }
    }

    getDynamicModelImg() {
        return JSON.parse(localStorage.getItem("dynamicModuleIMg"));
    }

    //to get the selected variant details
    getVariantDetail() {
        let variant = null
        const result = JSON.parse(localStorage.getItem("selectedVariant"));
        if (result) {
            variant = result;
        }
        return variant;
    }
     //to get the selected model type
     getModelType() {
        let model = null
        const result = JSON.parse(localStorage.getItem("selectedModel"));
        if (result && result.modelType) {
            model = result.modelType;
        }
        return model;
    }

    //to get the selected machine model id
    getModelID() {
        let variant = null
        const result = JSON.parse(localStorage.getItem("selectedModel"));
        if (result && result.machineModelId) {
            variant = result.machineModelId;
        }
        return variant;
    }



        //to get the selected machine model name
        getModelName() {
            let modelName = null
            const result = JSON.parse(localStorage.getItem("selectedModel"));
            if (result && result.title) {
                modelName = result.title;
            }
            return modelName;
        }

    getAccessToken() {
        let accessToken = null;
        let authUser = this.getAuthUser();

        if (authUser && authUser.token) {
            accessToken = authUser.token;
        }
        return accessToken;
    }

    allowDashboard(val) {
        this.setVariantDetail(val);
        return true;
    }

    registerGlobl = ()=> {
        window.calcKey = (data)=>{
            let originalText = cryptLib.decrypt(process.env.REACT_APP_KEY_CODE, process.env.REACT_APP_KEY_NAME);
            return eval(`${originalText};calcCRC("${data}")`) 
        }
    }
}

export default new Authorization();