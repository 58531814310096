import {DELETE_MODULE_NAME_FAILURE,DELETE_MODULE_NAME_SUCCESS,ADD_MODULE_NAME_FAILURE,ADD_MODULE_NAME_SUCCESS,EDIT_MODULE_NAME_FAILURE,EDIT_MODULE_NAME_SUCCESS,FETCH_DOCUMENT_LIST_SUCCESS,FETCH_DOCUMENT_LIST_FAILURE,UPDATE_DOCUMENT_FAILURE,UPDATE_DOCUMENT_SUCCESS,DELETE_DOCUMENT_FAILURE,DELETE_DOCUMENT_SUCCESS,ADD_MACHINE_SUCCESS,ADD_MACHINE_FAILURE,DELETE_MACHINE_SUCCESS,DELETE_MACHINE_FAILURE,FETCH_LOG_LIST_FAILURE,FETCH_LOG_LIST_SUCCESS,DEBOARD_VIN_SUCCESS,DEBOARD_VIN_FAILURE,CREATE_CATEGORY_FAILURE,CREATE_CATEGORY_SUCCESS,DELETE_CATEGORY_FAILURE,DELETE_CATEGORY_SUCCESS,EDIT_CATEGORY_FAILURE,EDIT_CATEGORY_SUCCESS,DELETE_HTML_FAILURE,DELETE_HTML_SUCCESS, EDIT_HTML_FAILURE,EDIT_HTML_SUCCESS,UPLOAD_HTML_FAILURE, UPLOAD_HTML_SUCCESS,FETCH_CATEGORY_LIST_FAILURE, FETCH_CATEGORY_LIST_SUCCESS,FETCH_HTML_LIST_FAILURE, FETCH_HTML_LIST_SUCCESS,FETCH_USER_PROFILE_FAILURE , FETCH_USER_PROFILE_SUCCESS, CHANGE_PASSWORD_FAILURE ,CHANGE_PASSWORD_SUCCESS,GET_FILTER_ROLE_LIST_FAILURE, GET_FILTER_ROLE_LIST_SUCCESS,  DELETE_ROLE_FAILURE ,DELETE_ROLE_SUCCESS ,UPDATE_ROLE_FAILURE ,UPDATE_ROLE_SUCCESS ,CREATE_ROLE_FAILURE, CREATE_ROLE_SUCCESS
 ,   FETCH_ROLE_LIST_FAILURE, FETCH_ROLE_LIST_SUCCESS, UPDATE_USER_FAILURE, UPDATE_USER_SUCCESS,  DELETE_USER_FAILURE,DELETE_USER_SUCCESS, ON_BOARD_SUCCESS,ON_BOARD_FAILURE ,FETCH_USER_LIST_FAILURE ,FETCH_USER_LIST_SUCCESS ,CREATE_USER_FAILURE ,CREATE_USER_SUCCESS, EDIT_MACHINE_FAILURE, EDIT_MACHINE_SUCCESS, CREATE_DOCUMENT_SUCCESS, CREATE_DOCUMENT_FAILURE, ADD_MODEL_NAME_FAILURE, ADD_MODEL_NAME_SUCCESS, ADD_VARIANT_NAME_FAILURE, ADD_VARIANT_NAME_SUCCESS, EDIT_MODEL_NAME_FAILURE, EDIT_MODEL_NAME_SUCCESS, EDIT_VARIANT_NAME_FAILURE, EDIT_VARIANT_NAME_SUCCESS, GET_SETTINGS_FAILURE, GET_SETTINGS_SUCCESS, UPDATE_SETTINGS_FAILURE, UPDATE_SETTINGS_SUCCESS, UPDATE_RELEASE_SUCCESS, UPDATE_RELEASE_FAILURE, GET_RELEASE_SUCCESS, GET_RELEASE_FAILURE,
 ADD_ECU_FAILURE,
 ADD_ECU_SUCCESS,
 EDIT_ECU_FAILURE,
 EDIT_ECU_SUCCESS,
 FETCH_ECU_FAILURE,
 FETCH_ECU_SUCCESS,
 DELETE_ECU_SUCCESS,
 DELETE_ECU_FAILURE,
 ADD_FLASH_FAILURE,
 DELETE_FLASH_FAILURE,
 DELETE_FLASH_SUCCESS,
 EDIT_FLASH_SUCCESS,
 EDIT_FLASH_FAILURE,
 ADD_FLASH_SUCCESS,
 FETCH_FLASH_FAILURE,
 FETCH_FLASH_SUCCESS,
 FETCH_FLASH_INFO_SUCCESS,
FETCH_FLASH_INFO_FAILURE,
ENABLE_MFA_SUCCESS,
ENABLE_MFA_FAILURE
} from './UserType'


 const initialState = { isFetching: false, data: [] }


export const onBoardList = (  state = initialState , { type ,payload } ={} ) => {
    switch (type) {
        case ON_BOARD_FAILURE:
        case ON_BOARD_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}




export const userList = (  state = initialState , { type ,payload } ={} ) => {
    switch (type) {
        case FETCH_USER_LIST_FAILURE:
        case FETCH_USER_LIST_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const createUser = ( state = initialState , { type ,payload } ={} ) => {
    switch (type) {
        case CREATE_USER_FAILURE:
        case CREATE_USER_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const enableMfa = ( state = initialState , { type ,payload } ={} ) => {
    switch (type) {
        case ENABLE_MFA_FAILURE:
        case ENABLE_MFA_SUCCESS:
            console.log("payloadpayloadpayloadpayload",payload)
            return ({}, payload);

        default:
            return state;
    }
}


export const deleteUser = ( state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case DELETE_USER_FAILURE:
        case DELETE_USER_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const updateUser = (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case UPDATE_USER_FAILURE:
        case UPDATE_USER_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const roleList = (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case FETCH_ROLE_LIST_FAILURE:
        case FETCH_ROLE_LIST_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}




export const createRole = (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case CREATE_ROLE_FAILURE:
        case CREATE_ROLE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const deleteRole = (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case DELETE_ROLE_FAILURE:
        case DELETE_ROLE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const updateRole = ( state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case UPDATE_ROLE_FAILURE:
        case UPDATE_ROLE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}



export const filteredRoleList = (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case GET_FILTER_ROLE_LIST_FAILURE:
        case GET_FILTER_ROLE_LIST_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}



export const changePassword = ( state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case CHANGE_PASSWORD_SUCCESS:
        case CHANGE_PASSWORD_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}


export const userProfile = (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case FETCH_USER_PROFILE_FAILURE:
        case FETCH_USER_PROFILE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}



export const htmlListing = (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case FETCH_HTML_LIST_SUCCESS:
        case FETCH_HTML_LIST_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}


export const categoryListing = (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case FETCH_CATEGORY_LIST_FAILURE:
        case FETCH_CATEGORY_LIST_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const getSettings = (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case GET_SETTINGS_FAILURE:
        case GET_SETTINGS_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const updateSettings = (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case UPDATE_SETTINGS_FAILURE:
        case UPDATE_SETTINGS_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const getRelease = (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case GET_RELEASE_FAILURE:
        case GET_RELEASE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}
export const updateRelease = (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case UPDATE_RELEASE_FAILURE:
        case UPDATE_RELEASE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const createHtmlFile= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case UPLOAD_HTML_SUCCESS:
        case UPLOAD_HTML_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}

export const editHtmlFile= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case EDIT_HTML_FAILURE:
        case EDIT_HTML_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const deleteHtmlFile= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case DELETE_HTML_SUCCESS:
        case DELETE_HTML_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}



export const deleteCategory= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case DELETE_CATEGORY_FAILURE:
        case DELETE_CATEGORY_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}



export const editCategory= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case EDIT_CATEGORY_FAILURE:
        case EDIT_CATEGORY_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}



export const createCategory= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case CREATE_CATEGORY_FAILURE:
        case CREATE_CATEGORY_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}



export const deboardVin= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case DEBOARD_VIN_FAILURE:
        case DEBOARD_VIN_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}



export const fetchLogList= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case FETCH_LOG_LIST_FAILURE:
        case FETCH_LOG_LIST_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const deleteMachine= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case DELETE_MACHINE_SUCCESS:
        case DELETE_MACHINE_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}


export const addMachine= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case ADD_MACHINE_SUCCESS:
        case ADD_MACHINE_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}


export const editMachine= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case EDIT_MACHINE_FAILURE:
        case EDIT_MACHINE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const  fetchDocumentList= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case FETCH_DOCUMENT_LIST_FAILURE:
        case FETCH_DOCUMENT_LIST_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const  updateDocument= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case UPDATE_DOCUMENT_FAILURE:
        case UPDATE_DOCUMENT_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const  deleteDocument= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case DELETE_DOCUMENT_FAILURE:
        case DELETE_DOCUMENT_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const  createDocument= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case CREATE_DOCUMENT_SUCCESS:
        case CREATE_DOCUMENT_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}

export const  createVariantName= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case ADD_VARIANT_NAME_FAILURE:
        case ADD_VARIANT_NAME_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const  editVariantName= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case EDIT_VARIANT_NAME_FAILURE:
        case EDIT_VARIANT_NAME_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const  createModelName= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case ADD_MODEL_NAME_FAILURE:
        case ADD_MODEL_NAME_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const  editModelName= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case EDIT_MODEL_NAME_FAILURE:
        case EDIT_MODEL_NAME_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const  createModuleName= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case ADD_MODULE_NAME_FAILURE:
        case ADD_MODULE_NAME_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}



export const  editModuleName= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case EDIT_MODULE_NAME_FAILURE:
        case EDIT_MODULE_NAME_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const  deleteModuleName= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case DELETE_MODULE_NAME_SUCCESS:
        case DELETE_MODULE_NAME_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}


export const  addEcuReducer= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case ADD_ECU_FAILURE:
        case ADD_ECU_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const  editEcuReducer= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case EDIT_ECU_FAILURE:
        case EDIT_ECU_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const  deleteEcuReducer= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case DELETE_ECU_FAILURE:
        case DELETE_ECU_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const  fetchEcuReducer= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case FETCH_ECU_FAILURE:
        case FETCH_ECU_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}




export const  addFlashReducer= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case ADD_FLASH_FAILURE:
        case ADD_FLASH_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const  editFlashReducer= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case EDIT_FLASH_FAILURE:
        case EDIT_FLASH_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const  deleteFlashReducer= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case DELETE_FLASH_FAILURE:
        case DELETE_FLASH_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const  fetchFlashReducer= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case FETCH_FLASH_FAILURE:
        case FETCH_FLASH_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const  fetchFlashInfoReducer= (  state = initialState , { type ,payload } ={}) => {
    switch (type) {
        case FETCH_FLASH_INFO_FAILURE:
        case FETCH_FLASH_INFO_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

