export const ON_BOARD_FAILURE = 'ON_BOARD_FAILURE';
export const ON_BOARD_SUCCESS = 'ON_BOARD_SUCCESS';

export const FETCH_USER_LIST_FAILURE = 'FETCH_USER_LIST_FAILURE';
export const FETCH_USER_LIST_SUCCESS = 'FETCH_USER_LIST_SUCCESS';

export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';

export const ENABLE_MFA_SUCCESS = 'ENABLE_MFA_SUCCESS';
export const ENABLE_MFA_FAILURE = 'ENABLE_MFA_FAILURE';

export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export const FETCH_ROLE_LIST_FAILURE = 'FETCH_ROLE_LIST_FAILURE';
export const FETCH_ROLE_LIST_SUCCESS = 'FETCH_ROLE_LIST_SUCCESS';

export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';

export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';

export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';

export const GET_FILTER_ROLE_LIST_FAILURE = 'GET_FILTER_ROLE_LIST_FAILURE';
export const GET_FILTER_ROLE_LIST_SUCCESS = 'GET_FILTER_ROLE_LIST_SUCCESS';

export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';

export const FETCH_HTML_LIST_FAILURE = 'FETCH_HTML_LIST_FAILURE';
export const FETCH_HTML_LIST_SUCCESS = 'FETCH_HTML_LIST_SUCCESS';

export const FETCH_CATEGORY_LIST_FAILURE = 'FETCH_CATEGORY_LIST_FAILURE';
export const FETCH_CATEGORY_LIST_SUCCESS = 'FETCH_CATEGORY_LIST_SUCCESS';


export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';

export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';


export const GET_RELEASE_FAILURE = 'GET_RELEASE_FAILURE';
export const GET_RELEASE_SUCCESS = 'GET_RELEASE_SUCCESS';

export const UPDATE_RELEASE_FAILURE = 'UPDATE_RELEASE_FAILURE';
export const UPDATE_RELEASE_SUCCESS = 'UPDATE_RELEASE_SUCCESS';

export const UPLOAD_HTML_FAILURE = 'UPLOAD_HTML_FAILURE';
export const UPLOAD_HTML_SUCCESS = 'UPLOAD_HTML_SUCCESS';

export const DELETE_HTML_FAILURE = 'DELETE_HTML_FAILURE';
export const DELETE_HTML_SUCCESS = 'DELETE_HTML_SUCCESS';

export const EDIT_HTML_FAILURE = 'EDIT_HTML_FAILURE';
export const EDIT_HTML_SUCCESS = 'EDIT_HTML_SUCCESS';

export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';

export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';

export const EDIT_CATEGORY_FAILURE = 'EDIT_CATEGORY_FAILURE';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';

export const DEBOARD_VIN_SUCCESS = 'DEBOARD_VIN_SUCCESS';
export const DEBOARD_VIN_FAILURE = 'DEBOARD_VIN_FAILURE';

export const DELETE_MACHINE_SUCCESS = 'DELETE_MACHINE_SUCCESS';
export const DELETE_MACHINE_FAILURE = 'DELETE_MACHINE_FAILURE';

export const ADD_MACHINE_SUCCESS = 'ADD_MACHINE_SUCCESS';
export const ADD_MACHINE_FAILURE = 'ADD_MACHINE_FAILURE';

export const EDIT_MACHINE_SUCCESS = 'EDIT_MACHINE_SUCCESS';
export const EDIT_MACHINE_FAILURE = 'EDIT_MACHINE_FAILURE';

export const FETCH_LOG_LIST_FAILURE = 'FETCH_LOG_LIST_FAILURE';
export const FETCH_LOG_LIST_SUCCESS = 'FETCH_LOG_LIST_SUCCESS';

export const FETCH_DOCUMENT_LIST_FAILURE = 'FETCH_DOCUMENT_LIST_FAILURE';
export const FETCH_DOCUMENT_LIST_SUCCESS = 'FETCH_DOCUMENT_LIST_SUCCESS';

export const UPDATE_DOCUMENT_FAILURE = 'UPDATE_DOCUMENT_FAILURE';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';

export const CREATE_DOCUMENT_FAILURE = 'CREATE_DOCUMENT_FAILURE';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';

export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';

export const ADD_MODULE_NAME_FAILURE = 'ADD_MODULE_NAME_FAILURE';
export const ADD_MODULE_NAME_SUCCESS = 'ADD_MODULE_NAME_SUCCESS';

export const ADD_MODEL_NAME_FAILURE = 'ADD_MODEL_NAME_FAILURE';
export const ADD_MODEL_NAME_SUCCESS = 'ADD_MODEL_NAME_SUCCESS';

export const ADD_VARIANT_NAME_FAILURE = 'ADD_VARIANT_NAME_FAILURE';
export const ADD_VARIANT_NAME_SUCCESS = 'ADD_VARIANT_NAME_SUCCESS';

export const EDIT_VARIANT_NAME_FAILURE = 'EDIT_VARIANT_NAME_FAILURE';
export const EDIT_VARIANT_NAME_SUCCESS = 'EDIT_VARIANT_NAME_SUCCESS';

export const EDIT_MODEL_NAME_FAILURE = 'EDIT_MODEL_NAME_FAILURE';
export const EDIT_MODEL_NAME_SUCCESS = 'EDIT_MODEL_NAME_SUCCESS';

export const EDIT_MODULE_NAME_FAILURE = 'EDIT_MODULE_NAME_FAILURE';
export const EDIT_MODULE_NAME_SUCCESS = 'EDIT_MODULE_NAME_SUCCESS';


export const DELETE_MODULE_NAME_FAILURE = 'DELETE_MODULE_NAME_FAILURE';
export const DELETE_MODULE_NAME_SUCCESS = 'DELETE_MODULE_NAME_SUCCESS';


export const FETCH_ECU_FAILURE = 'FETCH_ECU_FAILURE';
export const FETCH_ECU_SUCCESS = 'FETCH_ECU_SUCCESS';

export const ADD_ECU_FAILURE = 'ADD_ECU_FAILURE';
export const ADD_ECU_SUCCESS = 'ADD_ECU_SUCCESS';

export const EDIT_ECU_FAILURE = 'EDIT_ECU_FAILURE';
export const EDIT_ECU_SUCCESS = 'EDIT_ECU_SUCCESS';


export const DELETE_ECU_FAILURE = 'DELETE_ECU_FAILURE';
export const DELETE_ECU_SUCCESS = 'DELETE_ECU_SUCCESS';



export const FETCH_FLASH_FAILURE = 'FETCH_ECU_FAILURE';
export const FETCH_FLASH_SUCCESS = 'FETCH_ECU_SUCCESS';

export const FETCH_FLASH_INFO_SUCCESS = 'FETCH_FLASH_INFO_SUCCESS';
export const FETCH_FLASH_INFO_FAILURE = 'FETCH_FLASH_INFO_FAILURE'

export const ADD_FLASH_FAILURE = 'ADD_ECU_FAILURE';
export const ADD_FLASH_SUCCESS = 'ADD_ECU_SUCCESS';

export const EDIT_FLASH_FAILURE = 'EDIT_ECU_FAILURE';
export const EDIT_FLASH_SUCCESS = 'EDIT_ECU_SUCCESS';


export const DELETE_FLASH_FAILURE = 'DELETE_ECU_FAILURE';
export const DELETE_FLASH_SUCCESS = 'DELETE_ECU_SUCCESS';










