import HeaderComponent from '../header/HeaderComponent';
import FooterComponent from '../footer/FooterComponent';
import Router from '../../router/Router'
import { BrowserRouter } from 'react-router-dom';
import '../../common.scss';
import { ToastContainer } from 'react-toastify';
import React from 'react';
import ErrorBoundary from '../../utility/ErrorBoundary';
import NoInternetComponent from '../no-internet-popup/NoInternetComponent';
// import { Detector } from "react-detect-offline";

/*
*LayoutComponent() is used to set the proper pagelayout.This is our main layout
*We are call the Header and footer by default
*<Router/> is used to load the components based on the Router path
* we have imported this LayoutComponent in the index.js
*/
class LayoutComponent extends React.Component {

    constructor(props){
        super(props)
        this.state = { onLine: true}
    }

    componentDidMount(){
        window.addEventListener("offline", (event) => {
            this.setState({onLine : false})
          });
          window.addEventListener("online", (event) => {
            this.setState({onLine : true})
          });
    }


    checkOnline(){
        if(navigator.onLine){
            window.location.reload();
        }
    }
    
    render(){
        return (<BrowserRouter basename={process.env.REACT_APP_HOMEPAGE}>
                <ErrorBoundary>
                {window.location.pathname !== '/prediction' && <HeaderComponent />}
                <div >
                    {!this.state.onLine &&
                        <NoInternetComponent checkInternet={()=>this.checkOnline()}/>
                    }
                </div>

        <Router /><FooterComponent /><ToastContainer autoClose={2000} />
        </ErrorBoundary>
        </BrowserRouter>);
    }
}

export default LayoutComponent