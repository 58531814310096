import { toast } from 'react-toastify';
import { FaSync } from 'react-icons/fa'; 
import './CustomToastStyle.scss'

class CustomToast {
  success(val = "") {
    toast.success(val, {
      className: 'custom-toaster custom-success-message',
    });
  }

  error(val = "") {
    toast.error(val, {
      className: 'custom-toaster custom-err-message',
    });
  }

  warning(val = "") {
    toast.warn(val, {
      className: 'custom-toaster custom-warning-message',
    });
  }

  promise(promise, id, pending = "", success = "", error = "",fetchDetail) {
    toast.promise(
      promise,
      {
        pending: {
          render() {
            return(
            <div className="toast-content">
                <span>{pending}</span>
                <FaSync onClick={()=>fetchDetail()}/> 
              </div>  
            )
                   
              },
          className: 'toast-pending',
        },
        success: {
          render() {
            return success;
          },
          className: 'custom-toaster custom-success-message',
        },
        error: {
          render() {
            return error;
          },
          className: 'custom-toaster custom-err-message',
        },
      },
      {
        toastId: id,
        closeOnClick: false, 
        position: toast.POSITION.TOP_CENTER
      }
    );
  }
}

export default new CustomToast();
