import React from 'react';
import Logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import i18next from "i18next";
import Authorization from '../../utility/Authorization';
import ProfileMenuComponent from './ProfileMenuComponent'
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { getRelease } from "../user-related/UserAction";

/* Will Load the HeaderDashBoard Component once after the user login and model variant selection
 */

class HeaderDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {notifyRetrain:false, releaseVersion: '', toggleMenu: false, siderbarActive: false, userName: '', variantName: '', userType: '' ,modelName:"",profileMenu:false};

    }

    /*use to Toogle the sidebar */
    enableSidebar() {
        document.getElementById("root").classList.toggle("sidebar-active");
    }



    componentDidMount() {
        let machineName = ''
        const machine = Authorization.getModelName();
        const variantName = Authorization.getVariantDetail();
        this.props.getRelease();
        if (machine) {
            machineName = machine
        }
        this.setState({  variantName: variantName.variantTitle, modelName: machineName, releaseVersion: localStorage.getItem("releaseVersion")});

    }
    componentDidUpdate(prevProps,prevState){
        let machineName = ''
        const machine = Authorization.getModelName();
        const variantNameVal = Authorization.getVariantDetail();

        if (machine) {
            machineName = machine
        }

        const { variantName } = this.state;
        //if varaiant name is different we can upadte the title in header section
        if(prevState.variantName != variantNameVal.variantTitle){
            this.setState({  variantName: variantNameVal.variantTitle, modelName: machineName});
        }
        if (this.props.getReleaseStatus && prevProps.getReleaseStatus !== this.props.getReleaseStatus) {
            if (this.props.getReleaseStatus.status === 200) {
                this.setState({releaseVersion: this.props.getReleaseStatus.data.version})
            }
        }

        const { getNotifyRetrain } = this.props;
        if (getNotifyRetrain && prevProps.getNotifyRetrain !== getNotifyRetrain) {
            this.setState({ notifyRetrain:getNotifyRetrain})
          }
        }


    render() {
        return (
            <>
            <div className="header header-dashboard">
                <div className="header-col">
                    <div className="header-logo-dashboard">
                        <div className="menu-nav" onClick={() => this.enableSidebar()}>
                            <span className="menu-nav-items"></span>
                        </div>
                        <div className="header-logo-img">
                            <Link to="/">
                                <img src={Logo} alt={i18next.t('BRAND.SHORT_NAME')} loading="lazy" />
                            </Link>
                        </div>
                    </div>
                    <div className="header-text">
                        <h1>{this.state.modelName} - {this.state.variantName}</h1>
                        <p className="release">{i18next.t('HEADER.RELEASE_VERSION')} : {this.state.releaseVersion || "--"}</p>
                    </div>
                    <div className="header-login">
                        <ProfileMenuComponent/>
                    </div>
                </div>
            </div>
            
            {/* {this.state.notifyRetrain && 
           ( <div className='toster_msg toast-bounce'>
                    <p>Please Re Train the dataset</p>
                </div>)
            } */}
            </>
            
        );
    }
}


function mapStateToProps(state) {
    return {
        getReleaseStatus: state.getRelease,
        getNotifyRetrain: state.notifyRetrain
    };
}


/*
* mapDispatchToProps(dispatch) is used to dispatch data to the Action
*/
function mapDispatchToProps(dispatch) {
    return {
        getRelease:()=>dispatch(getRelease())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderDashboard))