export const LOGIN_DATA_FAILURE = "LOGIN_DATA_FAILURE"
export const LOGIN_DATA_SUCCESS= "LOGIN_DATA_SUCCESS"

export const VERIFY_MFA_LOGIN_SUCCESS = "VERIFY_MFA_LOGIN_SUCCESS"
export const VERIFY_MFA_LOGIN_FAILURE= "VERIFY_MFA_LOGIN_FAILURE"

export const VERIFY_MFA_REGISTER_SUCCESS = "VERIFY_MFA_LOGIN_SUCCESS"
export const VERIFY_MFA_REGISTER_FAILURE= "VERIFY_MFA_LOGIN_FAILURE"

export const FETCH_MFA_QR_FAILURE = "FETCH_MFA_QR_FAILURE"
export const FETCH_MFA_QR_SUCCESS= "FETCH_MFA_QR_SUCCESS"

export const FETCH_QR_FAILURE = "FETCH_QR_FAILURE"
export const FETCH_QR_SUCCESS= "FETCH_QR_SUCCESS"
