import { combineReducers } from 'redux';
import { forgotPassword } from '../../src/modules/login-related/forgot-password/ForgotPasswordReducer'
import { resetPassword } from '../../src/modules/login-related/reset-password/ResetPasswordReducer'
import { generateQRReducer, loginReducer, mfaLoginReducer, mfaQRReducer, mfaRegisterReducer } from '../../src/modules/login-related/login-page/LoginPageReducer'
import { updateMachineModel, MachineModelReducer,MachineModelNewReducer,createMDSCode,deleteMDSCode,verifyUserMDSCode,editMDSCode,fetchMDSCodeList,fetchMDSCodeListAll } from '../../src/modules/machine-model-List/MachineModelReducer'
import { updateVariant, PopupReducer, vinList } from '../../src/modules/variant-vin-popup/PopupReducer';
import { headerMobileBack } from '../../src/modules/header/HeaderReducer';
import { detailDashboardCount,detailDashboardMachineCount,detailDashboardDealerCount,detailDashboardLogCount } from '../../src/modules/home-page/detail-dashboard/DetailDashboardReducer';
import { checkPopupDeviceStatus, checkModelDeviceStatus, checkDeboardDeviceStatus, fetchMissingCombinationSlice } from '../../src/modules/device-shadow-popup/DeviceShadowReducer';
import { serviceList } from '../../src/modules/services/ServicesReducer';
import { dynamicModelList, diagonsticsListing, selectedDiagonstics, dashboardDiagonsticsList, dashboardSetupList, dashboardDataLoggerList, dashboardCanLoggerList } from '../../src/modules/dynamic-modules/DynamicModuleReducer';
import { resetTimer, faultCodeData } from '../../src/modules/faultcode/FaultCodeReducer';
import { deleteModuleName,createModuleName,createModelName,editModelName,editVariantName,createVariantName, editModuleName, fetchDocumentList, updateDocument, deleteDocument, createDocument, editMachine, addMachine, deleteMachine, fetchLogList, deboardVin, deleteCategory, createCategory, editCategory, editHtmlFile, deleteHtmlFile, createHtmlFile, categoryListing,updateSettings,getSettings, getRelease, updateRelease, changePassword, userProfile, onBoardList, userList, createUser, deleteUser, updateUser, roleList, createRole, deleteRole, updateRole, filteredRoleList, htmlListing, fetchEcuReducer, addEcuReducer, editEcuReducer, deleteEcuReducer, fetchFlashReducer, deleteFlashReducer, editFlashReducer, addFlashReducer, fetchFlashInfoReducer } from '../../src/modules/user-related/UserReducer';
import { HelpCategoryList, HelpSelectedCategory, HelpFaultCode, machineFailureProcedure, htmlFileCode, helpTab, } from '../../src/modules/help/HelpReducer';
import {createLensLabel,updateLensLabel,deleteLensLabel,getLensLabel, createLensImage, getLensImage,deleteLensImage,getPredictImage,modelTrainingImage, notifyRetrain, reviewLabelSaveImg, modelTrainingDetails, fetchHTMLPredict, modelStopTraining, modelTrainingStatus, refreshClickedStatus} from '../../src/modules/jcb-lens/LensReducer'
import { enableMfa } from '../modules/user-related/UserAction';

export const reducer = combineReducers({
    loginInfo: loginReducer,
    mfaRegister:mfaRegisterReducer,
    mfaLogin:mfaLoginReducer,
    mfaQR:mfaQRReducer,
    generateMfa: generateQRReducer,
    machineModelList: MachineModelReducer,
    machineRdDataList:MachineModelNewReducer,
    popupData: PopupReducer,
    serviceList,
    HelpCategoryList,
    HelpSelectedCategory,
    HelpFaultCode,
    machineFailureProcedure,
    htmlFileCode,
    helpTab,
    onBoardList,
    createUser,
    enableMfa,
    userList,
    deleteUser,
    updateUser,
    roleList,
    createRole,
    deleteRole,
    updateRole,
    filteredRoleList,
    headerMobileBack,
    faultCodeData,
    forgotPassword,
    resetPassword,
    changePassword,
    userProfile,
    vinList,
    diagonsticsListing,
    selectedDiagonstics,
    htmlListing,
    categoryListing,
    getSettings,
    updateSettings,
    getRelease,
    updateRelease,
    createHtmlFile,
    editHtmlFile,
    deleteHtmlFile,
    deleteCategory,
    createCategory,
    editCategory,
    deboardVin,
    fetchLogList,
    deleteMachine,
    addMachine,
    editMachine,
    updateMachineModel,
    updateVariant,
    resetTimer,
    fetchDocumentList,
    updateDocument,
    deleteDocument,
    createDocument,
    dashboardDiagonsticsList,
    dashboardSetupList,
    dashboardDataLoggerList,
    dashboardCanLoggerList,
    checkPopupDeviceStatus,
    checkModelDeviceStatus,
    fetchMissingCombinationSlice,
    checkDeboardDeviceStatus,
    dynamicModelList,
    createModuleName,
    createModelName,
    editModelName,
    editVariantName,
    createVariantName,
    createMDSCode,
    deleteMDSCode,
    verifyUserMDSCode,
    editMDSCode,
    fetchMDSCodeList,
    fetchMDSCodeListAll,
    editModuleName,
    detailDashboardCount,
    deleteModuleName,detailDashboardMachineCount,detailDashboardDealerCount,detailDashboardLogCount,
    createLensLabel,
    updateLensLabel,
    deleteLensLabel,
    getLensLabel,
    createLensImage,
    deleteLensImage,
    getLensImage,
    getPredictImage,
    modelTrainingImage,
    modelStopTraining,
    modelTrainingDetails,
    modelTrainingStatus,
    refreshClickedStatus,
    fetchHTMLPredict,
    notifyRetrain,
    reviewLabelSaveImg,
    addEcuReducer,
    editEcuReducer,
    deleteEcuReducer,
    fetchEcuReducer,
    addFlashReducer,
    editFlashReducer,
    deleteFlashReducer,
    fetchFlashReducer,
    fetchFlashInfoReducer
})
