
import  { FETCH_MFA_QR_FAILURE, FETCH_MFA_QR_SUCCESS, LOGIN_DATA_FAILURE, LOGIN_DATA_SUCCESS, VERIFY_MFA_LOGIN_FAILURE, VERIFY_MFA_LOGIN_SUCCESS, VERIFY_MFA_REGISTER_FAILURE, VERIFY_MFA_REGISTER_SUCCESS, FETCH_QR_FAILURE, FETCH_QR_SUCCESS } from './LoginPageType';
const initialState = { data: [] , isFetching: false }

export const loginReducer = (  state =  initialState , { type ,payload } = {}) => {
    switch (type) {
        case LOGIN_DATA_SUCCESS:
        case LOGIN_DATA_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}

export const mfaRegisterReducer = (  state =  initialState , { type ,payload } = {}) => {
    switch (type) {
        case VERIFY_MFA_REGISTER_FAILURE:
        case VERIFY_MFA_REGISTER_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const mfaLoginReducer = (  state =  initialState , { type ,payload } = {}) => {
    switch (type) {
        case VERIFY_MFA_LOGIN_FAILURE:
        case VERIFY_MFA_LOGIN_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const mfaQRReducer = (  state =  initialState , { type ,payload } = {}) => {
    switch (type) {
        case FETCH_MFA_QR_FAILURE:
        case FETCH_MFA_QR_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}
    
export const generateQRReducer = (  state =  initialState , { type ,payload } = {}) => {
    switch (type) {
        case FETCH_QR_FAILURE:
        case FETCH_QR_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}
       
