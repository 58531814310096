import React, { createContext, useRef, useContext } from 'react';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const toastPromiseRef = useRef(null);

  return (
    <ToastContext.Provider value={{ toastPromiseRef }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
